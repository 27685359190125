import React, { useEffect } from 'react';
import { pageTitle } from '../../helper';
import Cta from '../Cta';
import FunFact from '../FunFact';
import PageHeading from '../PageHeading';
import Div from '../Div';
import SectionHeading from '../SectionHeading';
import TeamSlider from '../Slider/TeamSlider';
import Spacing from '../Spacing';
import { Content1 } from '../Content_1/Content1';
import { ShuffleGrid } from '../Hero/Hero8';


export default function AboutPage() {
  pageTitle('About');

  const funfaceData = [
    {
      title: 'Global Happy Clients',
      factNumber: '40K',
    },
    {
      title: 'Project Completed',
      factNumber: '50K',
    },
    {
      title: 'Team Members',
      factNumber: '245',
    },
    {
      title: 'Digital products',
      factNumber: '550',
    },
  ];
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {/* Start Page Heading Section */}
      <PageHeading
        title="About Us"
        bgSrc="images/about_hero_bg.jpeg"
        pageLinkText="About Us"
      />
      {/* End Page Heading Section */}

      {/* Start About Section */}
      <Spacing lg="150" md="80" />
      <Div className="container">
        <Div className="row">
          <Div className="col-xl-5 col-lg-7">
            <SectionHeading
              title="Envify Media"
              subtitle="About Our Agency"
            >
              <Spacing lg="30" md="20" />
              <p className="cs-m0 cs-secondary_font">
                At the nexus of collaboration and innovation, our agency stands as a dynamic force which
                serves as a vital intermediary that orchestrates mutually beneficial partnerships
                between creators and brands. At our core, we are dedicated to navigating
                the ever-evolving realms of production, digital marketing, brand
                collaborations, PR, esports event management, and campaign strategy,
                setting the stage for enduring success in the dynamic landscape of today's
                interconnected industries. Whether it's shaping compelling narratives,
                managing esports events, or developing strategic campaign blueprints,
                we thrive on aligning the right strategies with the right brands and
                creators, fostering harmonious partnerships where each party not only
                thrives individually but contributes to the collective amplification of
                influence and market presence. In this multifaceted landscape, creators
                discover unparalleled opportunities for captivating brand collaborations,
                while brands tap into the authentic reach of our carefully curated creator network.
              </p>
              <Spacing lg="30" md="30" />
              <Div className="cs-separator cs-accent_bg"></Div>
              <Spacing lg="25" md="40" />
            </SectionHeading>
          </Div>
          <Div className="col-lg-5 offset-xl-2">
            <ShuffleGrid />
            {/* <img
              src="https://images.unsplash.com/photo-1531973576160-7125cd663d86?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
              alt="About"
              className="w-100 cs-radius_15"
            /> */}
            <Spacing lg="25" md="25" />
          </Div>
          {/* <Div className="col-lg-7">
            <img
              src="https://images.unsplash.com/photo-1625690303837-654c9666d2d0?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
              alt="About"
              className="w-100 cs-radius_15"
            />
            <Spacing lg="25" md="25" />
          </Div> */}
          {/* <Div className="col-lg-5">
            <img
              src="https://images.unsplash.com/photo-1560419015-7c427e8ae5ba?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
              alt="About"
              className="w-100 cs-radius_15"
            />
            <Spacing lg="25" md="25" />
          </Div> */}
        </Div>
      </Div>
      <Spacing lg="75" md="55" />
      {/* End About Section */}

      {/* Start Fun Fact Section */}
      {/* <Div className="container">
        <FunFact
          title="Our fun fact"
          subtitle="Sed ut perspiciatis unde omnis iste natus error voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis."
          data={funfaceData}
        />
      </Div> */}
      {/* End Fun Fact Section */}

      {/* Start Why Choose Section */}
      <Spacing lg="100" md="80" />
      <Div className="container">
        <Div className="row">
          <Div className="col-xl-5 col-lg-6">
            <Div className="cs-image_layer cs-style1">
              <Div className="cs-image_layer_in">
                <img
                  src="https://images.unsplash.com/photo-1587440871875-191322ee64b0?q=80&w=2942&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                  alt="About"
                  className="w-100 cs-radius_15"
                />
              </Div>
            </Div>
            <Spacing lg="0" md="40" />
          </Div>
          <Div className="col-xl-5 offset-xl-1 col-lg-6 ">
            <SectionHeading
              title="Enhancing the experience"
              subtitle="Our Vision"
            >
              <Spacing lg="30" md="20" />
              <p className="cs-m0 cs-secondary_font ">
                At Envify, our vision is rooted in the understanding of the emotions, hard work, and passion that creators invest in their content. We recognize the challenges they face in the competitive landscape of content creation.
              </p>
              <Spacing lg="15" md="15" />
              <p className="cs-m0 cs-secondary_font">
                Our commitment is to create an environment where creators not only enter the world of content but thrive and survive.
                <br />
                <br />
                We aim to enforce, amplify, and enhance the flourishing of creators and brands in this industry.
                <br />
                <br />
                Through fostering strategic creator-brand alliances, meticulous management, and tailored entertainment and marketing solutions, we pave the way for sustained success in the dynamic landscape of content creation.

              </p>
              <Spacing lg="30" md="30" />
              <Div className="cs-separator cs-accent_bg"></Div>
              <Spacing lg="25" md="0" />
            </SectionHeading>
          </Div>
        </Div>
      </Div>
      {/* End Why Choose Section */}
      {/* Start Our Mission Section */}
      <Spacing lg="60" md="30" />
      <Div>
        <Content1
          imageUrl1=""
          imageUrl2=""
          imageUrl3=""
          content="
          Amplify:  We strategically position our talent and clients for success by creating tailor-fitted networking solutions.
          Enhance: Opportunities for our clientele by providing access to various avenues putting them at the heart of what is happening.
          Enforce: Equal opportunity partnerships and enduring relationships in the dynamic realm of entertainment and creativity by providing a dedicated team of specialists supplying around-the-clock support and guidance for all our partners.
          "
          title="Our Mission" />
      </Div>
      {/* End Our Mission Section */}
      {/* Start Team Section */}
      <Spacing lg="145" md="80" />
      <Div className="container">
        <SectionHeading
          title="Awesome team <br/>members"
          subtitle="Our Team"
          variant="cs-style1"
        />
        <Spacing lg="55" md="45" />
        <TeamSlider />
      </Div>

      {/* End Team Section */}

      {/* Start CTA Section */}
      <Spacing lg="150" md="80" />
      <Div className="container">
        <Cta
          title="Let’s discuss & make <br />something <i>cool</i> together"
          btnText="Connect For Meeting"
          btnLink="/contact"
          bgSrc="/images/cta_bg.jpeg"
        />
      </Div>
      {/* End CTA Section */}
    </>
  );
}
