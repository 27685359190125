import React from 'react'
import Div from '../Div'
import ContactInfoWidget from '../Widget/ContactInfoWidget'
import MenuWidget from '../Widget/MenuWidget'
import Newsletter from '../Widget/Newsletter'
import SocialWidget from '../Widget/SocialWidget'
import TextWidget from '../Widget/TextWidget'
import './footer.scss'

export default function Footer({copyrightText, logoSrc, logoAlt, text}) {
  const copyrightLinks = [
    {
      title: 'Terms of Use',
      href: '/'
    },
    {
      title: 'Privacy Policy',
      href: '/'
    }
  ]
  
  const serviceMenu = [
    {
      title: 'Talent Management',
      href: '/service/talent-management'
    },
    {
      title: 'Production',
      href: '/service/production'
    },
    {
      title: 'Brand Collaboration',
      href: '/service/brand-collaboration'
    },
    {
      title: 'Campaign Startegy',
      href: '/service/campaign-strategy'
    },
  ]

  return (
    <footer className="cs-fooer">
      <Div className="cs-fooer_main">
        <Div className="container">
          <Div className="flex flex-wrap">
            <Div className="col-lg-3 col-sm-6">
              <Div className="cs-footer_item">
                <TextWidget 
                  logoSrc='/images/logo.png' 
                  logoAlt='Logo'
                  text =''
                />
                <SocialWidget/>
              </Div>
            </Div>
            <Div className="col-lg-3 col-sm-6">
              <Div className="cs-footer_item">
                <MenuWidget menuItems={serviceMenu} menuHeading='Services'/>
              </Div>
            </Div>
            <Div className="col-lg-3 col-sm-6">
              <Div className="cs-footer_item">
                <ContactInfoWidget title='Contact Us'/>
              </Div>
            </Div>
            <Div className="col-lg-3 col-sm-6">
              <Div className="cs-footer_item">
                <h1 className='cs-widget_title'>About Us</h1>
                <span className='cs-style1'>We facilitate partnerships between influencers and brands, ensuring mutually beneficial collaborations for impactful marketing campaigns that resonate with audiences.</span>
              </Div>
            </Div>
          </Div>
        </Div>
      </Div>
      <Div className="container">
        <Div className="cs-bottom_footer">
          <Div className="cs-bottom_footer_left">
            <Div className="cs-copyright">Copyright © 2024 Envify Media Pvt ltd.</Div>
          </Div>
          <Div className="cs-bottom_footer_right">
            {/* <MenuWidget menuItems={copyrightLinks} variant=' cs-style2'/> */}
          </Div>
        </Div>
      </Div>
    </footer>
  )
}
