import React from 'react';
import Div from '../Div';

export default function PageHeading2({ title, bgSrc, pageLinkText }) {
  return (
    <Div
      className="cs-page_heading cs-secondary_font cs-style1 cs-center text-center cs-bg"
      style={{ backgroundImage: `url(${bgSrc})` }}
    >
      <Div className="container">
        <Div className="cs-page_heading_in justify-start">
          <h1 className="cs-page_title md:text-9xl heading-font text-5xl cs-white_color max-w-[95%]">{title}</h1>
          <h1 className="cs-page_title md:text-9xl heading-font text-5xl stroke-text cs-white_color lg:-mt-[16.5rem] sm:-mt-[3.5rem] md:-mt-[16rem] -mt-[6.5rem] max-w-[95%]">{title}</h1>
          <ol className="breadcrumb text-uppercase">
          </ol>
        </Div>
      </Div>
    </Div>
  );
}
