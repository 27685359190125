import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { pageTitle } from '../../../helper'
import Accordion from '../../Accordion'
import Button from '../../Button'
import Cta from '../../Cta'
import IconBox from '../../IconBox'
import PageHeading2 from '../../PageHeading/PageHeading2'
import Div from '../../Div'
import SectionHeading from '../../SectionHeading'
import Spacing from '../../Spacing'


export default function EventManagement() {
  pageTitle('Event Management');
  const params = useParams();
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <PageHeading2 
        title='Event Management'
        bgSrc='/images/service_hero_bg.jpeg'
        pageLinkText={params.serviceDetailsId}
      />
      <Spacing lg='145' md='80'/>
      <Div className="container">
        <SectionHeading 
          title='“Orchestrate unforgettable gaming experiences, seamlessly blend competition, entertainment, and community engagement.”
          ' 
          subtitle='Envify Media' 
          variant='cs-style1 text-center'
        />
        <Spacing lg='90' md='45'/>
        <Div className="row">
          <Div className="col-lg-4">
            <IconBox
              icon='/images/icons/service_icon_1.svg'
              title='Strategic Tournament Planning:'
              subtitle="Design and execute esports tournaments that captivate participants and viewers, showcasing the best of competitive gaming."
            />
            <Spacing lg='30' md='30'/>
          </Div>
          <Div className="col-lg-4">
            <IconBox
              icon='/images/icons/service_icon_2.svg'
              title='Engaging Live Productions:'
              subtitle="Elevate the viewing experience with top-tier live productions, including expert commentary, high-quality streaming, and immersive stage setups."
            />
            <Spacing lg='30' md='30'/>
          </Div>
          <Div className="col-lg-4">
            <IconBox
              icon='/images/icons/service_icon_3.svg'
              title='Sponsorship Integration:'
              subtitle="Maximize brand exposure through strategic partnerships and seamless integration of sponsorships, creating mutually beneficial relationships.
              "
            />
            <Spacing lg='30' md='30'/>
          </Div>
        </Div>
      </Div>
      <Spacing lg='120' md='50'/>
      <Div className="container">
        <Div className="row align-items-center">
          <Div className="col-xl-5 col-lg-6">
            <Div className="cs-radius_15 cs-shine_hover_1">
              <img src="https://images.unsplash.com/photo-1633545505446-586bf83717f0?q=80&w=2944&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="Service" className='cs-radius_15 w-100' />
            </Div>
            <Spacing lg='0' md='40'/>
          </Div>
          <Div className="col-lg-6 offset-xl-1">
            <h2 className="cs-font_50 cs-m0">Our Approach</h2>
            <Spacing lg='50' md='30'/>
            <Div className="row">
              <Div className="col-lg-12">
                <ul>
                  <li>
                    <b> Strategic Event Development: </b> Elevate your esports events with meticulously planned strategies, fostering engagement and ensuring visibility across platforms.
                  </li>
                    <Spacing lg={20}/>
                    <li>
                    <b> Community Engagement Activation: </b> Transform your event's online presence by creating compelling profiles, reinvigorating inactive pages, and fostering an engaged esports community for maximum reach and heightened visibility.
                  </li>
                    <Spacing lg={20}/>
                    <li>
                    <b> Esports Marketing Tactics:  </b> Leverage our expertise in targeted ads, social media management, and SEO specifically tailored for the esports industry to amplify engagement and deliver a strategic event marketing approach.
                  </li>
                    <Spacing lg={20}/>
                    <li>
                    <b> End-to-End Event Solutions: </b> From conception to execution, we handle every aspect of your esports event, ensuring a flawless and immersive experience for players and audiences alike.
                  </li>
                    <Spacing lg={20}/>
                    <li>
                    <b>Community-Centric Design:  </b> Craft events that foster community engagement, build a loyal fan base, and establish your brand as a leading force in the esports scene.
                  </li>
                    <Spacing lg={20}/>
                </ul>
                </Div>
            </Div>
          </Div>
        </Div>
      </Div>
      {/* <TestimonialSlider /> */}
      {/* <Spacing lg='145' md='80'/> */}
      {/* <Div className="container cs-shape_wrap_4">
        <Div className="cs-shape_4"></Div>
        <Div className="cs-shape_4"></Div>
        <Div className="container">
          <Div className="row">
            <Div className="col-xl-5 col-lg-6">
              <SectionHeading
                title='Some pre questions and answers' 
                subtitle='FAQ’s'
              />
              <Spacing lg='90' md='45'/>
            </Div>
            <Div className="col-lg-6 offset-xl-1">
              <Accordion/>
            </Div>
          </Div>
        </Div>
      </Div> */}
      <Spacing lg='150' md='80'/>
      <Div className="container">
        <Cta 
          title='Let’s discuss & make <br />something <i>cool</i> together' 
          btnText='Connect For Meeting' 
          btnLink='/contact' 
          bgSrc='/images/cta_bg.jpeg'
        />
      </Div>
    </>
  )
}
