import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { pageTitle } from '../../../helper'
import Accordion from '../../Accordion'
import Button from '../../Button'
import Cta from '../../Cta'
import IconBox from '../../IconBox'
import PageHeading2 from '../../PageHeading/PageHeading2'
import Div from '../../Div'
import SectionHeading from '../../SectionHeading'
import Spacing from '../../Spacing'
import PageHeading from '../../PageHeading'


export default function Production() {
  pageTitle('Production');
  const params = useParams();
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <PageHeading 
        title='Production'
        bgSrc='/images/service_hero_bg.jpeg'
        pageLinkText={params.serviceDetailsId}
      />
      <Spacing lg='145' md='80'/>
      <Div className="container">
        <SectionHeading 
          title='Envify Media is your dedicated partner in achieving
          production excellence' 
          subtitle='Envify Media' 
          variant='cs-style1 text-center'
        />
        <Spacing lg='90' md='45'/>
        <Div className="row">
          <Div className="col-lg-4">
            <IconBox
              icon='/images/icons/service_icon_1.svg'
              title='Multifaceted Production Expertise:'
              subtitle='From videography and
              photography to animation and graphic design, our skilled
              professionals cover a spectrum of production needs'
            />
            <Spacing lg='30' md='30'/>
          </Div>
          <Div className="col-lg-4">
            <IconBox
              icon='/images/icons/service_icon_2.svg'
              title='Quality Assurance:'
              subtitle="We prioritize quality, delivering content that not
              only meets but exceeds expectations, with a keen eye on creativity
              and timely delivery.
              "
            />
            <Spacing lg='30' md='30'/>
          </Div>
          <Div className="col-lg-4">
            <IconBox
              icon='/images/icons/service_icon_3.svg'
              title='Brand Integration: '
              subtitle=' Ensure that every piece of content reflects and
              enhances your brand identity, fostering a consistent and
              memorable online presence.'
            />
            <Spacing lg='30' md='30'/>
          </Div>
        </Div>
      </Div>
      <Spacing lg='120' md='50'/>
      <Div className="container">
        <Div className="row align-items-center">
          <Div className="col-xl-5 col-lg-6">
            <Div className="cs-radius_15 cs-shine_hover_1">
              <img src="https://images.unsplash.com/photo-1471341971476-ae15ff5dd4ea?q=80&w=2832&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="Service" className='cs-radius_15 w-100' />
            </Div>
            <Spacing lg='0' md='40'/>
          </Div>
          <Div className="col-lg-6 offset-xl-1">
            <h2 className="cs-font_50 cs-m0">Our Approach</h2>
            <Spacing lg='50' md='30'/>
            <Div className="row">
              <Div className="col-lg-12">
                <ul>
                  <li>
                  <b>Multifaceted Expertise:</b> Leverage our diverse skill set encompassing videography, photography, animation, and graphic design to create captivating content that resonates with your audience.
                  </li>
                   <Spacing lg={20}/>
                   <li>
                   <b>Cutting-Edge Visual Storytelling:</b> Elevate your brand with visually stunning and impactful content, merging artistic flair and technical precision for a compelling narrative that captivates and engages.
                  </li>
                   <Spacing lg={20}/>
                   <li>
                   <b>Tailored Production Solutions:</b> Our professionals provide bespoke production services, understanding your unique brand identity and tailoring visual content that aligns seamlessly with your overall digital strategy.
                  </li>
                   <Spacing lg={20}/>
                   <li>
                   <b>Strategic Integration:</b> Ensure your production aligns strategically with your digital goals, creating a seamless blend of creativity and purpose that reinforces your brand narrative across various platforms.
                  </li>
                   <Spacing lg={20}/>
                   <li>
                   <b>Engagement-Driven Content:</b> Craft content that not only attracts attention but also encourages audience engagement, enhancing your online presence and building a loyal following.
                  </li>
                   <Spacing lg={20}/>
                </ul>
                </Div>
            </Div>
          </Div>
        </Div>
      </Div>
      {/* <TestimonialSlider /> */}
      {/* <Spacing lg='145' md='80'/> */}
      {/* <Div className="container cs-shape_wrap_4">
        <Div className="cs-shape_4"></Div>
        <Div className="cs-shape_4"></Div>
        <Div className="container">
          <Div className="row">
            <Div className="col-xl-5 col-lg-6">
              <SectionHeading
                title='Some pre questions and answers' 
                subtitle='FAQ’s'
              />
              <Spacing lg='90' md='45'/>
            </Div>
            <Div className="col-lg-6 offset-xl-1">
              <Accordion/>
            </Div>
          </Div>
        </Div>
      </Div> */}
      <Spacing lg='150' md='80'/>
      <Div className="container">
        <Cta 
          title='Let’s discuss & make <br />something <i>cool</i> together' 
          btnText='Connect For Meeting' 
          btnLink='/contact' 
          bgSrc='/images/cta_bg.jpeg'
        />
      </Div>
    </>
  )
}
