import React, { useState } from 'react'
import Button from '../Button'
import Div from '../Div'
import Spacing from '../Spacing'
import { ShuffleGrid } from '../Hero/Hero8'
import { Player } from 'video-react';
import { BigPlayButton } from 'video-react'

export default function Portfolio3({ title, subtitle, btnText, btnLink, content, category }) {
  const [hovered, setHovered] = useState(false);
  return (
    <Div className="cs-portfolio cs-style2 cs-type1">
      <Div className="cs-gradient_shape" />
      <Div className="container">
        <Div className="row align-items-center cs-column_reverse_lg">
          <Div className="col-xl-5 col-lg-6">
            <Spacing lg='0' md='35' />
            <Div className="cs-section_heading cs-style1">
              <h3 className="cs-section_subtitle">{subtitle}</h3>
              <h2 className="cs-section_title cs-primary_font w-full">{title}</h2>
              <span>
                {content}
              </span>
              <Spacing lg='45' md='20' />

              <span
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
              >
                <Button
                  btnLink={btnLink}
                  btnText={btnText}
                />
              </span>
            </Div>
          </Div>
          <Div className="col-lg-6 offset-xl-1">
            <Div className={hovered ? "cs-portfolio_img active" : "cs-portfolio_img"}>
              {/* <h3 className="cs-portfolio_img_title heading-font ">{category}</h3> */}
              {/* Start Video Block Section */}

              {/* <Div className="">
                      <Div className="container">
                        <VideoModal
                          videoSrc="https://www.youtube.com/watch?v=VcaAVWtP48A"
                          bgUrl="/images/video_bg_2.jpeg"
                        />
                      </Div>
                    </Div> */}
              <Player
                autoplay="autoplay"
                className='video-player rounded-[25px]'
                preload='auto'
                controls="controls"
                playsInline={true}
              >
                <BigPlayButton position='center' />
                <source
                  src="envify-media-intro.mp4"
                  type="video/mp4"
                />
              </Player>
              {/* End Video Block Section */}
              {/* <ShuffleGrid/> */}
              {/* <Div className="cs-portfolio_img_in cs-shine_hover_1 cs-radius_5"><img src={imageUrl} alt="Portfolio" className="cs-w100 cs-iconbox cs-radius_15" /></Div> */}
            </Div>
          </Div>
        </Div>
      </Div>
    </Div>
  )
}
