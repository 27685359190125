import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { pageTitle } from '../../../helper'
import Accordion from '../../Accordion'
import Button from '../../Button'
import Cta from '../../Cta'
import IconBox from '../../IconBox'
import PageHeading2 from '../../PageHeading/PageHeading2'
import Div from '../../Div'
import SectionHeading from '../../SectionHeading'
import Spacing from '../../Spacing'
import PageHeading from '../../PageHeading'

export default function CampaignStrategy() {
  pageTitle('Digital Marketing');
  const params = useParams();
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <PageHeading 
        title='Campaign Strategy'
        bgSrc='/images/service_hero_bg.jpeg'
        pageLinkText={params.serviceDetailsId}
      />
      <Spacing lg='145' md='80'/>
      <Div className="container">
        <SectionHeading 
          title='Envify Media is dedicated to elevating the brand presence of our clients.' 
          subtitle='Envify Media' 
          variant='cs-style1 text-center'
        />
        <Spacing lg='90' md='45'/>
        <Div className="row">
          <Div className="col-lg-4">
            <IconBox
              icon='/images/icons/service_icon_1.svg'
              title="Strategic Storytelling"
              subtitle='Develop compelling narratives that resonate with your audience, creating an emotional connection and lasting brand recall.”
              '
            />
            <Spacing lg='30' md='30'/>
          </Div>
          <Div className="col-lg-4">
            <IconBox
              icon='/images/icons/service_icon_2.svg'
              title='Data-Backed Decision Making:'
              subtitle="Utilize data analytics to track and measure campaign
              performance, enabling real-time adjustments for optimal results.
              "
            />
            <Spacing lg='30' md='30'/>
          </Div>
          <Div className="col-lg-4">
            <IconBox
              icon='/images/icons/service_icon_3.svg'
              title='Tailor-crafted Campaigns'
              subtitle=' We orchestrate comprehensive campaigns that surround
              your audience, leaving an indelible mark across all digital platforms.'
            />
            <Spacing lg='30' md='30'/>
          </Div>
        </Div>
      </Div>
      <Spacing lg='120' md='50'/>
      <Div className="container">
        <Div className="row align-items-center">
          <Div className="col-xl-5 col-lg-6">
            <Div className="cs-radius_15 cs-shine_hover_1">
              <img src="https://images.unsplash.com/photo-1432888498266-38ffec3eaf0a?q=80&w=2948&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="Service" className='cs-radius_15 w-100' />
            </Div>
            <Spacing lg='0' md='40'/>
          </Div>
          <Div className="col-lg-6 offset-xl-1">
            <h2 className="cs-font_50 cs-m0">Our Approach</h2>
            <Spacing lg='50' md='30'/>
            <Div className="row">
              <Div className="col-lg-12">
                <ul>
                  <li>
                  <b>Data-Infused Strategy Development:</b> Dive into insightful consumer data to shape campaign strategies, ensuring each move resonates with your target audience and converts potential customers into devoted patrons.
                  </li>
                    <Spacing lg={20}/>
                    <li>
                    <b>Cross-Channel Synergy:</b> Seamlessly integrate campaigns across a spectrum of media platforms, harnessing the unique strengths of each to maximize reach and impact. This approach maintains your brand's consistency and delivers a meaningful presence across diverse channels.
                  </li>
                    <Spacing lg={20}/>
                    <li>
                   <b> Performance Optimization:</b> Continually refine and optimize campaign strategies based on real-time data and analytics, ensuring your brand stays ahead of trends and adapts to evolving consumer behaviors.
                  </li>
                    <Spacing lg={20}/>
                    <li>
                    <b>Strategic Adaptability:</b> Stay agile in the ever-changing digital landscape by adapting campaigns to emerging trends and consumer preferences, ensuring your brand remains relevant and resonant.
                  </li>
                    <Spacing lg={20}/>
                    <li>
                    <b>Conversion-Driven Tactics:</b> Implement strategies that go beyond visibility to drive actual conversions, transforming campaign efforts into tangible results that contribute to your brand's overall success.
                  </li>
                    <Spacing lg={20}/>
                </ul>
                </Div>
            </Div>
          </Div>
        </Div>
      </Div>
      {/* <TestimonialSlider /> */}
      {/* <Spacing lg='145' md='80'/> */}
      {/* <Div className="container cs-shape_wrap_4">
        <Div className="cs-shape_4"></Div>
        <Div className="cs-shape_4"></Div>
        <Div className="container">
          <Div className="row">
            <Div className="col-xl-5 col-lg-6">
              <SectionHeading
                title='Some pre questions and answers' 
                subtitle='FAQ’s'
              />
              <Spacing lg='90' md='45'/>
            </Div>
            <Div className="col-lg-6 offset-xl-1">
              <Accordion/>
            </Div>
          </Div>
        </Div>
      </Div> */}
      <Spacing lg='150' md='80'/>
      <Div className="container">
        <Cta 
          title='Let’s discuss & make <br />something <i>cool</i> together' 
          btnText='Connect For Meeting' 
          btnLink='/contact' 
          bgSrc='/images/cta_bg.jpeg'
        />
      </Div>
    </>
  )
}
