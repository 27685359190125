import React from 'react'
import SectionHeading from '../SectionHeading';
import Spacing from '../Spacing';

export const Content1 = ({imageUrl1,imageUrl2,imageUrl3,content,title}) => {
    return (
      <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div className="grid gap-10 lg:grid-cols-2">
          <div className="flex flex-col justify-center md:pr-8 xl:pr-0 lg:max-w-lg">
            <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-teal-accent-400">
            </div>
            <div className="max-w-xl mb-6">
             
              <SectionHeading title="Enforce the talent" subtitle={title} />
                <Spacing lg={30} md={30}/>
              <p className="text-base text-gray-200 md:text-lg">
                
          <b>Amplify:</b>  We strategically position our talent and clients for success by creating tailor-fitted networking solutions.
          <br/>
          <br/>
          <b>Enhance:</b> Opportunities for our clientele by providing access to various avenues putting them at the heart of what is happening.
          <br/>
          <br/>
          <b>Enforce:</b> Equal opportunity partnerships and enduring relationships in the dynamic realm of entertainment and creativity by providing a dedicated team of specialists supplying around-the-clock support and guidance for all our partners.
          
              </p>
            </div>
          </div>
          <div className="flex items-center justify-center -mx-4 lg:pl-8">
            <div className="flex flex-col items-end px-3">
              <img
                className="object-cover mb-6 rounded shadow-lg h-28 sm:h-48 xl:h-56 w-28 sm:w-48 xl:w-56"
                src="https://images.pexels.com/photos/3184287/pexels-photo-3184287.jpeg?auto=compress&amp;cs=tinysrgb&amp;dpr=2&amp;h=750&amp;w=1260"
                alt=""
              />
              <img
                className="object-cover w-20 h-20 rounded shadow-lg sm:h-32 xl:h-40 sm:w-32 xl:w-40"
                src="https://images.pexels.com/photos/3182812/pexels-photo-3182812.jpeg?auto=compress&amp;cs=tinysrgb&amp;dpr=2&amp;h=750&amp;w=1260"
                alt=""
              />
            </div>
            <div className="px-3">
              <img
                className="object-cover w-40 h-40 rounded shadow-lg sm:h-64 xl:h-80 sm:w-64 xl:w-80"
                src="https://images.pexels.com/photos/3182739/pexels-photo-3182739.jpeg?auto=compress&amp;cs=tinysrgb&amp;dpr=2&amp;w=500"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    );
  };