import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import CustomCursor from '../CustomCursor';
import Footer from '../Footer';
import Header from '../Header';
import { Loader } from '../Loader/Loader';

export default function Layout({ headerVariant }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [isLoader,setLoader] = useState(true)

  useState(()=>{
    setTimeout(()=>{
      setLoader(false);
    },6000)
  })

  return (
    <>
       {isLoader?<Loader/>
      :<>
      <Header variant={headerVariant} />
      <Outlet />
      <CustomCursor />
      <Footer />
      
      </>}
      
    </>
  );
}
