import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { pageTitle } from '../../../helper'
import Accordion from '../../Accordion'
import Button from '../../Button'
import Cta from '../../Cta'
import IconBox from '../../IconBox'
import PageHeading2 from '../../PageHeading/PageHeading2'
import Div from '../../Div'
import SectionHeading from '../../SectionHeading'
import Spacing from '../../Spacing'
import PageHeading from '../../PageHeading'


export default function DigitalMarketing() {
  pageTitle('Digital Marketing');
  const params = useParams();
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <PageHeading
        title='Digital Marketing'
        bgSrc='/images/service_hero_bg.jpeg'
        pageLinkText={params.serviceDetailsId}
      />
      <Spacing lg='145' md='80' />
      <Div className="container">
        <SectionHeading
          title="Unleash Digital Marketing Excellence for Your Brand"
          subtitle='Envify Media'
          variant='cs-style1 text-center'
        />
        <Spacing lg='90' md='45' />
        <Div className="row">
          <Div className="col-lg-4">
            <IconBox
              icon='/images/icons/service_icon_1.svg'
              title='Key Metrics & Social Media Analytics:'
              subtitle='Key Metrics: Track Impressions, CTR, Engagement Rate, Conversion Rate.
              <br/>
              <br/>
              Social Media Analytics: Breakdown by Platform (Twitter, Instagram, Facebook,
              Twitch). Monitor Follower Growth, Post Reach, Likes, Comments, Shares.'
            />
            <Spacing lg='30' md='30' />
          </Div>
          <Div className="col-lg-4">
            <IconBox
              icon='/images/icons/service_icon_2.svg'
              title='Campaign Effectiveness & Content Engagement:'
              subtitle="Campaign Effectiveness: Assess Recent Campaigns' ROI.Analyze Cost per
              Conversion, Revenue Generated, and Audience Reach.
              <br/>
              <br/>
              Content Engagement: Showcase Top Performing Posts. Highlight Engaging
              Content Themes and Video Metrics.
              "
            />
            <Spacing lg='30' md='30' />
          </Div>
          <Div className="col-lg-4">
            <IconBox
              icon='/images/icons/service_icon_3.svg'
              title='Future Strategies'
              subtitle=' Identify & Optimize Opportunities. Plan Teasers,
              Challenges, and Collaborations.
              <br/>
              <br/>
              At Envify, we stand for creators and brands, thus, it is our responsibility to identify and forecast key trends that can enhance the digital presence of any of our clients.
              '
            />
            <Spacing lg='30' md='30' />
          </Div>
        </Div>
      </Div>
      <Spacing lg='120' md='50' />
      <Div className="container">
        <Div className="row align-items-center">
          <Div className="col-xl-5 col-lg-6">
            <Div className="cs-radius_15 cs-shine_hover_1">
              <img src="https://images.unsplash.com/photo-1533750349088-cd871a92f312?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="Service" className='cs-radius_15 w-100' />
            </Div>
            <Spacing lg='0' md='40' />
          </Div>
          <Div className="col-lg-6 offset-xl-1">
            <h2 className="cs-font_50 cs-m0">Our Approach</h2>
            <Spacing lg='50' md='30' />
            <Div className="row">
              <Div className="col-lg-12">
                <ul>
                  <li>
                    <b>Strategic Web Development:</b> Elevate your brand with expertly crafted websites, designed for both user engagement and search engine visibility.
                  </li>
                  <Spacing lg={20} />
                  <li>
                    <b>Social Media Revitalization:</b> Transform your online presence by creating impactful profiles and reinvigorating inactive pages, ensuring maximum reach and heightened visibility.

                  </li>
                  <Spacing lg={20} />
                  <li>
                    <b>Engagement-Driven Tactics: </b> Leverage our proficiency in paid ads, social media management, and SEO to amplify engagement across all platforms, delivering a targeted and effective digital marketing approach
                  </li>
                  <Spacing lg={20} />
                  <li>
                    <b>Data-Driven Optimization: </b> Benefit from result-oriented campaigns, utilizing data-driven insights to continually refine and optimize your digital marketing strategies for maximum impact.

                  </li>
                  <Spacing lg={20} />
                  <li>
                    <b>Comprehensive Digital Solutions:</b> From web development to social media management and SEO, our end-to-end solutions are designed to ensure your brand's digital success with a focus on measurable results.
                  </li>
                </ul>
              </Div>
            </Div>
          </Div>
        </Div>
      </Div>
      {/* <TestimonialSlider /> */}
      {/* <Spacing lg='145' md='80'/> */}
      {/* <Div className="container cs-shape_wrap_4">
        <Div className="cs-shape_4"></Div>
        <Div className="cs-shape_4"></Div>
        <Div className="container">
          <Div className="row">
            <Div className="col-xl-5 col-lg-6">
              <SectionHeading
                title='Some pre questions and answers' 
                subtitle='FAQ’s'
              />
              <Spacing lg='90' md='45'/>
            </Div>
            <Div className="col-lg-6 offset-xl-1">
              <Accordion/>
            </Div>
          </Div>
        </Div>
      </Div> */}
      <Spacing lg='150' md='80' />
      <Div className="container">
        <Cta
          title='Let’s discuss & make <br />something <i>cool</i> together'
          btnText='Connect For Meeting'
          btnLink='/contact'
          bgSrc='/images/cta_bg.jpeg'
        />
      </Div>
    </>
  )
}
