import React from 'react'
import { useGlobalContext } from '../../GlobalContext';
import { Icon } from '@iconify/react';
import Div from '../Div';

export default function SocialWidget() {

  const {EnvifySocial} = useGlobalContext()

  return (
    <Div className="cs-social_btns cs-style1">
      <a href={EnvifySocial.LinkedIn} className="cs-center">
        <Icon icon="fa6-brands:linkedin-in" />
      </a>
      <a href={EnvifySocial.twitter} className="cs-center">
        <Icon icon="fa6-brands:twitter" />               
      </a>
      <a href={EnvifySocial.Youtube} className="cs-center">
        <Icon icon="fa6-brands:youtube" />              
      </a>
      <a href={EnvifySocial.Instagram} className="cs-center">
        <Icon icon="fa6-brands:instagram" />
      </a>
    </Div>
  )
}
