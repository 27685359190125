import { Icon } from '@iconify/react';
import React, { useEffect, useState, useRef } from 'react';
import { pageTitle } from '../../helper';
import Div from '../Div';
import PageHeading from '../PageHeading';
import SectionHeading from '../SectionHeading';
import Spacing from '../Spacing';
import ContactInfoWidget from '../Widget/ContactInfoWidget';
import emailjs from '@emailjs/browser';
import Modal from '../Modal/Modal';
import CtaSection from '../Cta2/CtaCreator';
import CreatorForm from '../Form/CreatorForm';
import CtaBrand from '../Cta2/CtaBrand';

export default function ContactPage() {
  pageTitle('Contact Us');
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  return (
    <>
      <PageHeading
        title="Contact Us"
        bgSrc="/images/contact_hero_bg.jpeg"
        pageLinkText="Contact"
      />
      <Spacing lg="150" md="80" />
      <Div className="flex justify-evenly flex-wrap">
        <CtaSection title={'Influencer'} ButtonText={"Join Now"} />
        <CtaBrand title={'2024'} ButtonText={"Connect With Us"} />
      </Div>
      <Spacing lg={200} />
      {/* <Div className="cs-google_map">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d96652.27317354927!2d-74.33557928194516!3d40.79756494697628!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c3a82f1352d0dd%3A0x81d4f72c4435aab5!2sTroy+Meadows+Wetlands!5e0!3m2!1sen!2sbd!4v1563075599994!5m2!1sen!2sbd"
          allowFullScreen
          title="Google Map"
        />
      </Div> */}
      <Spacing lg="50" md="40" />
    </>
  );
}
