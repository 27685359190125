import React from 'react'
import Div from '../Div'
import './logolist.scss'

export default function LogoList() {
  const partnerLogos = [
    {
      src: '/images/partner_1_2.png',
      alt: 'Partner'
    },
    {
      src: '/images/partner_2_2.png',
      alt: 'Partner'
    },
    {
      src: '/images/partner_3_2.png',
      alt: 'Partner'
    },
    {
      src: '/images/partner_4_2.png',
      alt: 'Partner'
    },
    {
      src: '/images/partner_5_2.png',
      alt: 'Partner'
    },
    {
      src: '/images/partner_6.png',
      alt: 'Partner'
    }
  ]
  return (
    <Div className="cs-partner_logo_wrap flex-wrap">
      {partnerLogos.map((partnerLogo, index) => <div className="cs-partner_logo  max-w-48 grayscale hover:grayscale-0 duration-500 hover:shadow-lg flex justify-center items-center" key={index}><img src={partnerLogo.src} alt={partnerLogo.alt} className='drop-shadow-sm h-20 object-contain  shadow-white' /></div>)}
    </Div>
  )
}
