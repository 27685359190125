const EnvifySocial = {
  Instagram:
    "https://www.instagram.com/envify.media?igsh=MWk1bnFlOWlvMTR1ZQ%3D%3D&utm_source=qr",
  Youtube: "https://www.linkedin.com/company/envify-media/",
  LinkedIn: "https://www.linkedin.com/company/envify-media/",
  twitter: "https://twitter.com/envifyin",
};

const teamData = [
  {
    memberImage: "/images/member_1.png",
    memberName: "Vishal Singh Nathani ",
    memberDesignation: "CEO & Founder",
    memberSocial: {
      linkedin:
        "https://www.linkedin.com/in/vishal-nathani-657bbb13a?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app",
      twitter: EnvifySocial.twitter,
      youtube: "https://www.youtube.com/@Arcadyyy",
      Instagram:
        "https://www.instagram.com/vishalnathani34?igsh=MTc5dmZsdG5lcXJ5eA%3D%3D&utm_source=qr",
    },
  },
  {
    memberImage: "/images/member_5.png",
    memberName: "Ritesh Sahitya",
    memberDesignation: "CCO",
    memberSocial: {
      linkedin: "https://www.linkedin.com/in/ritesh-sahitya-796607195/",
      twitter: EnvifySocial.twitter,
      youtube: "https://www.youtube.com/@ONzo._.",
      Instagram: "https://www.instagram.com/onzoiwnl/",
    },
  },
  // {
  //   memberImage: "/images/member_3.png",
  //   memberName: "Jay Thakur",
  //   memberDesignation: "Head Of Operations",
  //   memberSocial: {
  //     linkedin:
  //       "https://www.linkedin.com/in/jay-thakur-a14abb264?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
  //     twitter: EnvifySocial.twitter,
  //     youtube: EnvifySocial.Youtube,
  //     Instagram:
  //       "https://www.instagram.com/jayythakur?igsh=MWxhcjQ2MzVlZTFyOQ==",
  //   },
  // },
  {
    memberImage: "/images/member_2.png",
    memberName: "Nikhil Bhalla",
    memberDesignation: "CIO",
    memberSocial: {
      linkedin: "/",
      twitter: "/",
      youtube: "/",
      Instagram: "/",
    },
  },
  {
    memberImage: "/images/member_4.png",
    memberName: "Ayush Mishra",
    memberDesignation: "CMO",
    memberSocial: {
      linkedin:
        "https://www.linkedin.com/in/theayushmishra101?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
      twitter: "https://x.com/IAmMishChief?t=DA_cVv9SqBi8nSRW3zfFog&s=09",
      youtube: EnvifySocial.Youtube,
      Instagram:
        "https://www.instagram.com/theayushmishra?igsh=ZWRubG16NjlnaGxo",
    },
  },
  {
    memberImage: "/images/member_6.png",
    memberName: "Mann Jadwani",
    memberDesignation: "Head Of Tech",
    memberSocial: {
      linkedin: "https://www.linkedin.com/in/mann-jadwani-3415b4238/",
      twitter: "https://twitter.com/MannJadwani",
      youtube: "https://www.youtube.com/@mannjadwani",
      Instagram: "https://www.instagram.com/memannjadwani/",
    },
  },
];

export const useGlobalContext = () => {
  return {
    teamData,
    EnvifySocial,
  };
};
