import React from 'react';
import parse from 'html-react-parser';
import Button from '../Button';
import Div from '../Div';
import VerticalLinks from '../VerticalLinks';
import Spline from '@splinetool/react-spline';
import { ShuffleGrid } from './Hero8';


export default function Hero5({
  title,
  subtitle,
  btnLink,
  btnText,
  bgImageUrl,
  socialLinksHeading,
  heroSocialLinks,
}) {
  return (
    <Div
      className="cs-hero cs-style3 cs-bg cs-fixed_bg cs-shape_wrap_1 cs-background-image-container"
      id="home"
    >
      <Div className="cs-shape_1" />
      <Div className="cs-shape_1" />
      <Div className="cs-shape_1" />
      {/* <Div className="cs-circle_1" /> */}
      {/* <Div className="cs-circle_2" /> */}
      <Div className=" ">
        {/* <Spline className='3dmodel' style={{zIndex:"20",width:"101vw"}} scene="https://prod.spline.design/mPrQlt7U6woix3Lf/scene.splinecode" />  */}
        <video
          autoplay="autoplay"
          disableDefaultControls={true}
          preload='auto'
          muted
          className="video-player lg:-mt-28 lg:-mb-28 md:block hidden"
          playsInline={true}
          loop
        >
          <source src="3dModal.webm" type="video/webm"

          />
        </video>
        <img src="3dModal-Phone.png" alt="" className='md:hidden -mt-12 -mr-20 -mb-12' />


        {/* <video autoPlay muted loop className='lg:-mt-28 lg:-mb-28' >
      <source src="3dModal.webm" type="video/mp4"/>
     </video> */}
        <Div className="cs-hero_text flex items-center">
          {/* <h1 className="cs-hero_title text-end">{parse(title)}</h1> */}
          <Button btnLink={btnLink} btnText={btnText} />
          <Div className="cs-hero_subtitle flex items-center">{subtitle}</Div>
        </Div>
      </Div>
      <VerticalLinks
        data={heroSocialLinks}
        title={socialLinksHeading}
        variant="cs-left_side"
      />
    </Div>
  );
}
