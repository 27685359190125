import React from 'react'

export const Loader = () => {

  return (
    <div className='w-screen h-screen flex align-middle justify-center bg-black'>
      <video
        autoplay="autoplay"
        width="300px"
        height="400px"
        preload='auto'
        muted
        className="video-player"
        playsInline={true}
      >
        <source src="loader.mp4" type="video/mp4" />
      </video>
    </div>
  )
}
