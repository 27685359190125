import React, { useEffect } from 'react';
import Cta from '../Cta';
import Div from '../Div';
import Hero5 from '../Hero/Hero5';
import LogoList from '../LogoList';
import MovingText from '../MovingText';
import Spacing from '../Spacing';
import VideoModal from '../VideoModal';
import { pageTitle } from '../../helper';
import PostSlider from '../Slider/PostSlider';
import SectionHeading from '../SectionHeading';
import TestimonialSlider from '../Slider/TestimonialSlider';
import PricingTableList from '../PricingTable/PricingTableList';
import FunFact2 from '../FunFact/FunFact2';
import PortfolioSlider2 from '../Slider/PortfolioSlider2';
import ServiceList from '../ServiceList';
import { AboutSection } from '../TailwindComponents/AboutSection/AboutSection';
import Portfolio3 from '../Portfolio/Portfolio3';
import { useGlobalContext } from '../../GlobalContext';

export default function MarketingAgencyHome() {

  const { EnvifySocial } = useGlobalContext();

  pageTitle('Marketing Agency');
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const heroSocialLinks = [
    {
      name: 'Instagram',
      links: EnvifySocial.Instagram,
    },
    {
      name: 'Twitter',
      links: EnvifySocial.twitter,
    },
  ];
  const funfaceData = [
    {
      title: 'Digital products',
      factNumber: '550',
    },
    {
      title: 'Global happy clients',
      factNumber: '40K',
    },
    {
      title: 'Project completed',
      factNumber: '50k',
    },
    {
      title: 'Team members',
      factNumber: '250',
    },
  ];
  const showcaseData = [
    {
      title: 'Uber food app <br />case study',
      imgUrl: '/images/slider_5.jpeg',
      href: '/case-study/case-study-details',
    },
    {
      title: 'Uber food app <br />case study',
      imgUrl: '/images/slider_6.jpeg',
      href: '/case-study/case-study-details',
    },
    {
      title: 'Uber food app <br />case study',
      imgUrl: '/images/slider_7.jpeg',
      href: '/case-study/case-study-details',
    },
    {
      title: 'Uber food app <br />case study',
      imgUrl: '/images/slider_8.jpeg',
      href: '/case-study/case-study-details',
    },
    {
      title: 'Uber food app <br />case study',
      imgUrl: '/images/slider_9.jpeg',
      href: '/case-study/case-study-details',
    },
  ];
  const AboutUs = {
    title: 'WHO ARE WE?',
    Content: 'In the dynamic landscape of influencer marketing, our role as the pivotal prism positions us as the catalyst for synergistic connections between influential individuals and forward-thinking brands. With a commitment to cultivating mutually beneficial collaborations, our expertise lies in the strategic development of partnerships that transcend traditional boundaries. In essence, we are not just facilitators; we are architects of impactful and resonant narratives that elevate brands and influencers alike in the competitive realm of digital marketing.',
    btnText: 'Learn More',
    category: 'About Us',
  }
  return (
    <>
      {/* Start Hero Section */}

      <Hero5
        title="Connect With <br/>Envify"
        subtitle="We Deliver Quality"
        btnLink="contact"
        btnText="Let’s talk"
        socialLinksHeading="Follow Us"
        heroSocialLinks={heroSocialLinks}
      />


      {/* <Hero8/> */}
      {/* End Hero Section */}

      {/* Start About Block Section */}
      <Spacing lg="145" md="45" />
      <Div key={1}>
        <Portfolio3
          title={AboutUs.title}
          btnText={AboutUs.btnText}
          content={AboutUs.Content}
          category={AboutUs.category}
        />
      </Div>
      <Spacing lg="145" md="80" />

      {/* End About Block Section */}


      {/* Start Services Section */}
      <Spacing lg="145" md="80" />
      <Div className="container">
        <SectionHeading
          title="Our core services"
          subtitle="Services"
          variant="cs-style1 text-center"
        />
        <Spacing lg="70" md="45" />
        <ServiceList />
      </Div>
      {/* End Services Section */}

      {/* Start PortfolioSlider Section */}
      {/* <Spacing lg="120" md="50" />
        <Div className="container">
          <h2 className="cs-font_50 cs-m0 cs-line_height_4">
            Our agile process is ability to adapt and respond to change. Agile
            organizations view change as an opportunity, not a threat.
          </h2>
        </Div>
        <Spacing lg="90" md="70" />
        <PortfolioSlider2 /> */}
      {/* End PortfolioSlider Section */}

      {/* Start FunFact Section */}
      {/* <Spacing lg="150" md="80" />
        <Div className="container">
          <FunFact2
            data={funfaceData}
            variant="cs-no_shadow"
            bgUrl="/images/funfact_shape_bg.svg"
          />
        </Div> */}
      {/* End FunFact Section */}

      {/* Start Pricing Section */}
      {/* <Spacing lg="145" md="80" />
        <Div className="container">
          <SectionHeading
            title="Providing best <br/>pricing for client"
            subtitle="Pricing & Packaging"
          />
          <Spacing lg="85" md="40" />
          <PricingTableList />
        </Div>
        <Spacing lg="125" md="55" /> */}
      {/* End Pricing Section */}

      {/* Start Testimonial Section */}
      {/* <TestimonialSlider /> */}
      {/* End Testimonial Section */}

      {/* Start Blog Section */}
      {/* <Spacing lg="150" md="80" />
        <Div className="cs-shape_wrap_4">
          <Div className="cs-shape_4"></Div>
          <Div className="cs-shape_4"></Div>
          <Div className="container">
            <Div className="row">
              <Div className="col-xl-4">
                <SectionHeading
                  title="Explore recent publication"
                  subtitle="Our Blog"
                  btnText="View More Blog"
                  btnLink="/blog"
                />
                <Spacing lg="90" md="45" />
              </Div>
              <Div className="col-xl-7 offset-xl-1">
                <Div className="cs-half_of_full_width">
                  <PostSlider />
                </Div>
              </Div>
            </Div>
          </Div>
      </Div> */}
      {/* End Blog Section */}

      {/* Start MovingText Section */}
      <Spacing lg="125" md="70" />
      <MovingText text="Our reputed world wide partners" />
      <Spacing lg="100" md="70" />
      {/* End MovingText Section */}

      {/* Start LogoList Section */}
      <Div className="container">
        <LogoList />
      </Div>
      <Spacing lg="130" md="80" />
      {/* End LogoList Section */}

      {/* Start CTA Section */}
      <Div className="container">
        <Cta
          title="Let’s discuss & make <br />something <i>cool</i> together"
          btnText="Connect For Meeting"
          btnLink="/contact"
          bgSrc="/images/cta_bg_3.jpeg"
        />
      </Div>
      {/* End CTA Section */}
    </>
  );
}
