import React, { useEffect } from 'react';
import { pageTitle } from '../../helper';
import Cta from '../Cta';
import PageHeading from '../PageHeading';
import Div from '../Div';
import SectionHeading from '../SectionHeading';
import Spacing from '../Spacing';
import Team from '../Team';
import { useGlobalContext } from '../../GlobalContext';

export default function TeamPage() {
  pageTitle('Team');
  const {teamData} = useGlobalContext();
  const advisoryBoard = [
    {
      memberImage: '/images/member_7.jpeg',
      memberName: 'Pratapsingh Nathani',
      memberDesignation: 'Mentor & Advisor',
      memberSocial: {
        linkedin: '/',
        twitter: '/',
        youtube: '/',
        Instagram: '/',
      },
    },
    {
      memberImage: '/images/member_6.jpeg',
      memberName: 'Ameya Mankar',
      memberDesignation: 'Mentor & Advisory',
      memberSocial: {
        linkedin: '/',
        twitter: '/',
        youtube: '/',
        Instagram: '/',
      },
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <PageHeading
        title="Our Team"
        bgSrc="images/team_hero_bg.jpeg"
        pageLinkText="Team"
      />
      <Spacing lg="145" md="80" />
      <Div className="container">
        <SectionHeading
          title="Meet our awesome <br/>team members"
          subtitle="Our Team"
          variant="cs-style1 text-center"
        />
        <Spacing lg="90" md="45" />
        <Div className="row">
          {teamData.map((item, index) => (
            <Div key={index} className="col-lg-3 col-sm-6">
              <Team
                memberImage={item.memberImage}
                memberName={item.memberName}
                memberDesignation={item.memberDesignation}
                memberSocial={item.memberSocial}
              />
              <Spacing lg="80" md="30" />
            </Div>
          ))}
        </Div>
        <Spacing lg="70" md="50" />
        <SectionHeading
              title="Advisory Board"
              subtitle="Envify Media"
            />
        <Spacing lg="70" md="50" />

        <Div className="row">
          {advisoryBoard.map((item, index) => (
            <Div key={index} className="col-lg-3 col-sm-6">
              <Team
                memberImage={item.memberImage}
                memberName={item.memberName}
                memberDesignation={item.memberDesignation}
                memberSocial={item.memberSocial}
              />
              <Spacing lg="80" md="30" />
            </Div>
          ))}
        </Div>
        <Spacing lg="70" md="50" />
        <Div className="container">
          <Cta
            title="Let’s discuss & make <br />something <i>cool</i> together"
            btnText="Connect For Meeting"
            btnLink="/contact"
            bgSrc="/images/cta_bg.jpeg"
          />
        </Div>
      </Div>
    </>
  );
}
