import Spacing from '../Spacing';
import ContactInfoWidget from '../Widget/ContactInfoWidget';
import emailjs from '@emailjs/browser';
import { pageTitle } from '../../helper';
import { useEffect, useState, useRef } from 'react';
import { Icon } from '@iconify/react';
import Div from '../Div';
import PageHeading from '../PageHeading';
import SectionHeading from '../SectionHeading';

export default function CreatorForm() {
  pageTitle('Contact Us');
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');
  const [message, setMessage] = useState('');
  const [platforms, setPlatforms] = useState([]);
  const [otherPlatform, setOtherPlatform] = useState('');
  const [isOtherPlatformChecked, setIsOtherPlatformChecked] = useState(false);
  const [followerBase, setFollowerBase] = useState('');

  const validateEmail = (email) => {
    var regex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    return regex.test(email);
  };
  const form = useRef();

  const handlePlatformChange = (e) => {
    const value = e.target.value;
    if (value === 'Other') {
      setIsOtherPlatformChecked(!isOtherPlatformChecked);
    } else {
      const updatedPlatforms = e.target.checked
        ? [...platforms, value]
        : platforms.filter((platform) => platform !== value);
      setPlatforms(updatedPlatforms);
    }
  };

  const handleOtherPlatformChange = (e) => {
    setOtherPlatform(e.target.value);
  };

  const handleFollowerBaseChange = (e) => {
    setFollowerBase(e.target.value);
  };

  const sendMessage = (e) => {
    e.preventDefault();
    console.log(validateEmail(email));

    if (validateEmail(email)) {
      console.log(`
        Name:${name}
        Email:${email}
        Mobile:${mobile}
        Message:${message}
        Platform:${platforms}
        OtherPlatform:${otherPlatform}
        Follower Base:${followerBase}
      `);

      emailjs
        .sendForm(
          'service_yqohe0c',
          'template_ti9fm0q',
          form.current,
          'JQCFmJ6Bw_B6xvt8l'
        )
        .then(
          (result) => {
            console.log(result.text);
          },
          (error) => {
            console.log(error.text);
          }
        );
    }
  };

  return (
    <>
      <Div className="container">
        <Div className="row">
          <Div className="col-lg-6">
            <SectionHeading
              title="Do you have a project <br/>in your mind?"
              subtitle="Get In Touch"
            />
            <Spacing lg="55" md="30" />
            <ContactInfoWidget withIcon />
            <Spacing lg="0" md="50" />
          </Div>
          <Div className="col-lg-6">
            <form action="#" ref={form} className="row">
              <Div className="col-sm-6">
                <label className="cs-primary_color">Your Name</label>
                <input
                  type="text"
                  className="cs-form_field"
                  name="user_name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <Spacing lg="20" md="20" />
              </Div>
              <Div className="col-sm-6">
                <label className="cs-primary_color">Email*</label>
                <input
                  type="email"
                  className="cs-form_field"
                  name="user_email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <Spacing lg="20" md="20" />
              </Div>
              <Div className="col-sm-12">
                <label className="cs-primary_color">Mobile*</label>
                <input
                  type="number"
                  className="cs-form_field"
                  name="user_phoneNumber"
                  value={mobile}
                  onChange={(e) => setMobile(e.target.value)}
                />
                <Spacing lg="20" md="20" />
              </Div>
              <Div className="col-sm-12">
                <label className="cs-primary_color">Social Media links</label>
                <textarea
                  cols="30"
                  rows="7"
                  className="cs-form_field"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  name="message"
                ></textarea>
                <Spacing lg="25" md="25" />
              </Div>
              <Div className="col-sm-12">
                <label className="cs-primary_color">Platform</label>
                <Div className="flex item-center justify-around flex-column ">
                  <Div className="flex items-center justify-evenly">
                    <Div>
                      <input
                        type="checkbox"
                        name="platform"
                        value="Instagram"
                        onChange={handlePlatformChange}
                      />
                      <label>Instagram</label>
                    </Div>

                    <Div>
                      <input
                        type="checkbox"
                        name="platform"
                        value="YouTube"
                        onChange={handlePlatformChange}
                      />
                      <label>YouTube</label>
                    </Div>

                    <Div>
                      <input
                        type="checkbox"
                        name="platform"
                        value="Facebook"
                        onChange={handlePlatformChange}
                      />
                      <label>Facebook</label>
                    </Div>
                  </Div>
                  <Div className="flex items-center justify-evenly">
                    <Div>
                      <input
                        type="checkbox"
                        name="platform"
                        value="Snapchat"
                        onChange={handlePlatformChange}
                      />
                      <label>Snapchat</label>
                    </Div>

                    <Div>
                      <input
                        type="checkbox"
                        name="platform"
                        value="Twitter"
                        onChange={handlePlatformChange}
                      />
                      <label>Twitter</label>
                    </Div>

                    <Div>
                      <input
                        type="checkbox"
                        name="platform"
                        value="Other"
                        onChange={handlePlatformChange}
                      />
                      <label>Other</label>
                    </Div>
                    {isOtherPlatformChecked && (
                      <input
                        type="text"
                        className="cs-form_field"
                        name="otherPlatform"
                        value={otherPlatform}
                        onChange={handleOtherPlatformChange}
                        placeholder="Specify other platform"
                      />
                    )}
                  </Div>
                </Div>
                <Spacing lg="20" md="20" />
              </Div>
              <Div className="col-sm-12">
                <label className="cs-primary_color">Follower Base</label>
                <div>
                  <select
                    className="cs-form_field"
                    name="follower_base"
                    value={followerBase}
                    onChange={handleFollowerBaseChange}
                  >  
                    <option value="">Select Follower Base</option>
                      <option value="Nano">Nano (less than 10k)</option>
                    <option value="Micro">Micro (10k to 100k)</option>
                    <option value="Macro">Macro (100k to 500k)</option>
                    <option value="Mega">Mega (more than 500K)</option>
                  </select>
                  <Spacing lg="20" md="20" />
                </div>
              </Div>
              <Div className="col-sm-12">
                <button className="cs-btn cs-style1" onClick={(e) => sendMessage(e)}>
                  <span>Send Message</span>
                  <Icon icon="bi:arrow-right" />
                </button>
              </Div>
            </form>
          </Div>
        </Div>
      </Div>
    </>
  );
}
