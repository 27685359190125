import React from 'react'
import { Icon } from '@iconify/react';

export default function ContactInfoWidget({ withIcon, title }) {
  return (
    <>
      {title && <h2 className="cs-widget_title">{title}</h2>}
      <ul className="cs-menu_widget cs-style1 cs-mp0">
        <li>
          {withIcon ? <span className='cs-accent_color'><Icon icon="material-symbols:add-call-rounded" /></span> : ''}
          {/* +91 8452822198 */}
        </li>
        <li>
          {withIcon ? <span className='cs-accent_color'><Icon icon="mdi:envelope" /></span> : ''}
          <a href="mailto:Core@envify.co.in"> You can connect with us at<br/>Core@envify.co.in</a>
        </li>
        <li>
          {withIcon ? <span className='cs-accent_color'><Icon icon="mdi:map-marker" /></span> : ''}
          {/* F801,Jay Balaji CHS, Sector 6, plot no 26,Nerul,Navi Mumbai, 400706 */}
        </li>
      </ul>
    </>
  )
}
