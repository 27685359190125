import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { pageTitle } from '../../../helper'
import Accordion from '../../Accordion'
import Button from '../../Button'
import Cta from '../../Cta'
import IconBox from '../../IconBox'
import PageHeading2 from '../../PageHeading/PageHeading2'
import Div from '../../Div'
import SectionHeading from '../../SectionHeading'
import Spacing from '../../Spacing'


export default function TalentManagement() {
  pageTitle('Service Details');
  const params = useParams();
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <PageHeading2 
        title='Talent Management'
        bgSrc='/images/service_hero_bg.jpeg'
        pageLinkText={params.serviceDetailsId}
      />
      <Spacing lg='145' md='80'/>
      <Div className="container">
        <SectionHeading 
          title='Forge powerful alliances with industry influencers' 
          subtitle='Envify Media' 
          variant='cs-style1 text-center'
        />
        <Spacing lg='90' md='45'/>
        <Div className="row">
          <Div className="col-lg-4">
            <IconBox
              icon='/images/icons/service_icon_1.svg'
              title='360-Degree Representation:'
              subtitle='From contract negotiations to brand
              endorsements, we handle every aspect of talent representation, providing a
              comprehensive and seamless experience.'
            />
            <Spacing lg='30' md='30'/>
          </Div>
          <Div className="col-lg-4">
            <IconBox
              icon='/images/icons/service_icon_2.svg'
              title='Brand Building:'
              subtitle="Cultivate and enhance your organization and creator's brand
              through strategic marketing, social media management, and curated
              promotional campaigns"
            />
            <Spacing lg='30' md='30'/>
          </Div>
          <Div className="col-lg-4">
            <IconBox
              icon='/images/icons/service_icon_3.svg'
              title='Industry Insights'
              subtitle='Stay ahead of the curve with our in-depth market research
              and industry intelligence, keeping your talent informed and prepared for
              emerging trends.'
            />
            <Spacing lg='30' md='30'/>
          </Div>
        </Div>
      </Div>
      <Spacing lg='120' md='50'/>
      <Div className="container">
        <Div className="row align-items-center">
          <Div className="col-xl-5 col-lg-6">
            <Div className="cs-radius_15 cs-shine_hover_1">
              <img src="https://images.unsplash.com/photo-1628017974670-846f66fc7671?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTR8fHRhbGVudHxlbnwwfHwwfHx8MA%3D%3D"
               alt="Service" className='cs-radius_15 w-100' />
            </Div>
            <Spacing lg='0' md='40'/>
          </Div>
          <Div className="col-lg-6 offset-xl-1">
            <h2 className="cs-font_50 cs-m0">Our Approach</h2>
            <Spacing lg='50' md='30'/>
            <Div className="row">
              <Div className="col-lg-12">
                <ul>
                 <li>
                  <b>Influential Collaborations:</b>Cultivate impactful connections by establishing strategic partnerships with key influencers, brands, and trendsetters, amplifying your talent's visibility and reach in the industry.
                 </li>
                  <Spacing lg={20}/>
                 <li>
                  <b>Tailored Talent Strategies:</b>Develop personalized career roadmaps for each talent, aligning their aspirations with current industry trends and opportunities. Our approach ensures a customized strategy for sustained success in the competitive landscape.
                 </li>
                 <Spacing lg={20}/>

                 <li>
                  <b>Brand Affiliation Expertise:</b>Leverage our proficiency in brand collaborations, ensuring seamless integration of talents with relevant brands to maximize exposure and endorsement opportunities.
                 </li>
                 <Spacing lg={20}/>

                 <li>
                  <b>Performance Analytics:</b>Utilize advanced analytics to monitor and assess talent performance, identifying growth areas and refining strategies for continuous improvement in visibility and engagement.

                 </li>
                 <Spacing lg={20}/>

                 <li>
                  <b>Industry Trend Alignment:</b>Stay ahead of the curve by aligning talents with emerging industry trends, positioning them as trendsetters and thought leaders in their respective niches.
                 </li>
                </ul>
                </Div>
            </Div>
          </Div>
        </Div>
      </Div>
      {/* <TestimonialSlider /> */}
      {/* <Spacing lg='145' md='80'/> */}
      {/* <Div className="container cs-shape_wrap_4">
        <Div className="cs-shape_4"></Div>
        <Div className="cs-shape_4"></Div>
        <Div className="container">
          <Div className="row">
            <Div className="col-xl-5 col-lg-6">
              <SectionHeading
                title='Some pre questions and answers' 
                subtitle='FAQ’s'
              />
              <Spacing lg='90' md='45'/>
            </Div>
            <Div className="col-lg-6 offset-xl-1">
              <Accordion/>
            </Div>
          </Div>
        </Div>
      </Div> */}
      <Spacing lg='150' md='80'/>
      <Div className="container">
        <Cta 
          title='Let’s discuss & make <br />something <i>cool</i> together' 
          btnText='Connect For Meeting' 
          btnLink='/contact' 
          bgSrc='/images/cta_bg.jpeg'
        />
      </Div>
    </>
  )
}
