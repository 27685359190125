import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { pageTitle } from '../../../helper'
import Accordion from '../../Accordion'
import Button from '../../Button'
import Cta from '../../Cta'
import IconBox from '../../IconBox'
import PageHeading2 from '../../PageHeading/PageHeading2'
import Div from '../../Div'
import SectionHeading from '../../SectionHeading'
import Spacing from '../../Spacing'
import PageHeading from '../../PageHeading'


export default function PublicRelations() {
  pageTitle('Public Relations');
  const params = useParams();
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <PageHeading 
        title='Public Relations'
        bgSrc='/images/service_hero_bg.jpeg'
        pageLinkText={params.serviceDetailsId}
      />
      <Spacing lg='145' md='80'/>
      <Div className="container">
        <SectionHeading 
          title='visibility, credibility, and fan loyalty.' 
          subtitle='Envify Media' 
          variant='cs-style1 text-center'
        />
        <Spacing lg='90' md='45'/>
        <Div className="row">
          <Div className="col-lg-4">
            <IconBox
              icon='/images/icons/service_icon_1.svg'
              title='Multifaceted Production Expertise:'
              subtitle="Share the organization's journey through multimedia content."
            />
            <Spacing lg='30' md='30'/>
          </Div>
          <Div className="col-lg-4">
            <IconBox
              icon='/images/icons/service_icon_2.svg'
              title='Quality Assurance:'
              subtitle="Cultivate relationships for press coverage and features.
              "
            />
            <Spacing lg='30' md='30'/>
          </Div>
          <Div className="col-lg-4">
            <IconBox
              icon='/images/icons/service_icon_3.svg'
              title='Brand Integration: '
              subtitle=' Cultivate relationships for press coverage and features & Identify brand-aligned collaborations to enhance visibility'
            />
            <Spacing lg='30' md='30'/>
          </Div>
        </Div>
      </Div>
      <Spacing lg='120' md='50'/>
      <Div className="container">
        <Div className="row align-items-center">
          <Div className="col-xl-5 col-lg-6">
            <Div className="cs-radius_15 cs-shine_hover_1">
              <img src="https://images.unsplash.com/photo-1497015289639-54688650d173?q=80&w=2832&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="Service" className='cs-radius_15 w-100' />
            </Div>
            <Spacing lg='0' md='40'/>
          </Div>
          <Div className="col-lg-6 offset-xl-1">
            <h2 className="cs-font_50 cs-m0">Our Approach</h2>
            <Spacing lg='50' md='30'/>
            <Div className="row">
              <Div className="col-lg-12">
                <ul>
                  <li>
                  Proactive Media Engagement:
- Establish and nurture relationships with gaming journalists,
esports publications, and influential bloggers. Actively pitch and
share compelling story angles, ensuring that 2EZ Esports remains
top-of-mind for media outlets, resulting in increased press




                  </li>
                    <Spacing lg={20}/>
                  <li>
                  coverage and features.
Strategic Storytelling: Develop compelling narratives that
resonate with your audience, creating an emotional connection
and lasting brand recall.
                  </li>
                </ul>
                </Div>
            </Div>
          </Div>
        </Div>
      </Div>
      {/* <TestimonialSlider /> */}
      {/* <Spacing lg='145' md='80'/> */}
      {/* <Div className="container cs-shape_wrap_4">
        <Div className="cs-shape_4"></Div>
        <Div className="cs-shape_4"></Div>
        <Div className="container">
          <Div className="row">
            <Div className="col-xl-5 col-lg-6">
              <SectionHeading
                title='Some pre questions and answers' 
                subtitle='FAQ’s'
              />
              <Spacing lg='90' md='45'/>
            </Div>
            <Div className="col-lg-6 offset-xl-1">
              <Accordion/>
            </Div>
          </Div>
        </Div>
      </Div> */}
      <Spacing lg='150' md='80'/>
      <Div className="container">
        <Cta 
          title='Let’s discuss & make <br />something <i>cool</i> together' 
          btnText='Connect For Meeting' 
          btnLink='/contact' 
          bgSrc='/images/cta_bg.jpeg'
        />
      </Div>
    </>
  )
}
