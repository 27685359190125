import Spacing from '../Spacing';
import ContactInfoWidget from '../Widget/ContactInfoWidget';
import emailjs from '@emailjs/browser';
import { pageTitle } from '../../helper';
import { useEffect, useState, useRef } from 'react';
import { Icon } from '@iconify/react';
import Div from '../Div';
import PageHeading from '../PageHeading';
import SectionHeading from '../SectionHeading';

export default function BrandForm() {
  pageTitle('Brand Inquiry');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  //
  const [brandName, setBrandName] = useState('');
  const [mobile, setMobile] = useState('');
  const [email, setEmail] = useState('');
  const [socialMedia, setSocialMedia] = useState({
    instagram: false,
    facebook: false,
    snapchat: false,
    twitter: false,
    other: false,
  });
  const [campaignObjective, setCampaignObjective] = useState({
    productLaunch: false,
    brandAwareness: false,
    salesGrowth: false,
    websiteTraffic: false,
    appDownloads: false,
    followerGrowth: false,
    otherObjective: false,
  });
  const [creatorType, setCreatorType] = useState('');
  const [budget, setBudget] = useState('');
  const [brandDescription, setBrandDescription] = useState('');

  const [isOtherSelectedObjective, setIsOtherSelectedObjective] = useState(false);
  const [otherObjectiveValue, setOtherObjectiveValue] = useState('');

  const [isOtherSelected, setIsOtherSelected] = useState(false);
  const [otherValue, setOtherValue] = useState('');

const ClearInput=()=>{
  setBrandName('');
  setMobile('')
  setEmail('')
  setSocialMedia('')
  setCampaignObjective('')
  setCreatorType('')
  setBudget('')
  setBrandDescription('')
  setIsOtherSelected(false)
  setOtherObjectiveValue('')
  setOtherValue('') 
}

  const form = useRef();

  const validateEmail = (email) => {
    var regex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    return regex.test(email);
  };

  const socialMediaValues = Object.entries(socialMedia)
  .filter(([key, value]) => value)
  .map(([key]) => key)
  .join(', ');

const campaignObjectiveValues = Object.entries(campaignObjective)
  .filter(([key, value]) => value)
  .map(([key]) => key)
  .join(', ');

  const sendMessage = (e) => {
    e.preventDefault();


    console.log(`
      Brand Name: ${brandName}
      Mobile: ${mobile}
      Email: ${email}
      Social Media: ${socialMediaValues}
      Campaign Objective: ${campaignObjectiveValues}
      Creator Type: ${creatorType}
      Budget: ${budget}
      Brand Description: ${brandDescription}
      Other Value: ${otherValue}
      Other Objective Value: ${otherObjectiveValue}
    `);
    emailjs
        .sendForm(
          'service_yqohe0c',
          'template_0fpnwyc',
          form.current,
          'JQCFmJ6Bw_B6xvt8l'
        )
        .then(
          (result) => {
            console.log(result.text);
            ClearInput()
          },
          (error) => {
            console.log(error.text);
          }
        );
  };

  return (
    <>
      <Spacing lg="150" md="80" />
      <Div className="container">
        <Div className="row">
          <Div className="col-lg-6">
            <SectionHeading
              title="Do you have a project <br/>in your mind?"
              subtitle="Get In Touch"
            />
            <Spacing lg="55" md="30" />
            <ContactInfoWidget withIcon />
            <Spacing lg="0" md="50" />
          </Div>
          <Div className="col-lg-6">
            <form action="#" ref={form} className="row">
              <Div className="col-sm-6">
                <label className="cs-primary_color">Brand Name*</label>
                <input
                  type="text"
                  className="cs-form_field"
                  name="brand_name"
                  value={brandName}
                  onChange={(e) => setBrandName(e.target.value)}
                />
                <Spacing lg="20" md="20" />
              </Div>
              <Div className="col-sm-6">
                <label className="cs-primary_color">Mobile*</label>
                <input
                  type="number"
                  className="cs-form_field"
                  name="brand_mobile"
                  value={mobile}
                  onChange={(e) => setMobile(e.target.value)}
                />
                <Spacing lg="20" md="20" />
              </Div>
              <Div className="col-sm-6">
                <label className="cs-primary_color">Email*</label>
                <input
                  type="email"
                  className="cs-form_field"
                  name="brand_email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <Spacing lg="20" md="20" />
              </Div>
              <Div className="col-sm-6 text-start">
                <label className="cs-primary_color">Choose Your Creator*</label>
                <select
                  className="cs-form_field"
                  value={creatorType}
                  onChange={(e) => setCreatorType(e.target.value)}
                  name="creator_type"
                >
                  <option value="">Select Creator Type</option>
                  <option value="Nano">Nano</option>
                  <option value="Micro">Micro</option>
                  <option value="Macro">Macro</option>
                  <option value="Mega">Mega</option>
                  <option value="Celebrity">Celebrity</option>
                </select>
                <Spacing lg="20" md="20" />
              </Div>
              <Div className="col-sm-6 text-start">
                <label className="cs-primary_color">Social Media*</label>
                <Div className="text-start">
                  <Div>
                    <label>
                      <input
                        type="checkbox"
                        checked={socialMedia.instagram}
                        onChange={(e) =>
                          setSocialMedia({
                            ...socialMedia,
                            instagram: e.target.checked,
                          })
                        }
                      />
                      Instagram
                    </label>
                  </Div>
                  <Div>
                    <label>
                      <input
                        type="checkbox"
                        checked={socialMedia.facebook}
                        onChange={(e) =>
                          setSocialMedia({
                            ...socialMedia,
                            facebook: e.target.checked,
                          })
                        }
                      />
                      Facebook
                    </label>
                  </Div>
                  <Div>
                    <label>
                      <input
                        type="checkbox"
                        checked={socialMedia.snapchat}
                        onChange={(e) =>
                          setSocialMedia({
                            ...socialMedia,
                            snapchat: e.target.checked,
                          })
                        }
                      />
                      Snapchat
                    </label>
                  </Div>
                  <Div>
                    <label>
                      <input
                        type="checkbox"
                        checked={socialMedia.twitter}
                        onChange={(e) =>
                          setSocialMedia({
                            ...socialMedia,
                            twitter: e.target.checked,
                          })
                        }
                      />
                      Twitter
                    </label>
                  </Div>
                  <Div>
                    <label>
                      <input
                        type="checkbox"
                        checked={socialMedia.other}
                        onChange={(e) => {
                          setSocialMedia({
                            ...socialMedia,
                            other: e.target.checked,
                          });
                          setIsOtherSelected(e.target.checked);
                        }}
                      />
                      Other
                    </label>
                  </Div>
                  <input type="text" value={socialMediaValues} name='platform' style={{display:'none'}}/>
                  {isOtherSelected && (
                    <Div>
                      <label className="cs-primary_color">Other Social Media Platform</label>
                      <input
                        type="text"
                        className="cs-form_field"
                        name="otherPlatform"
                        value={otherValue}
                        onChange={(e) => setOtherValue(e.target.value)}
                      />
                      <Spacing lg="20" md="20" />
                    </Div>
                  )}
                </Div>
                <Spacing lg="20" md="20" />
              </Div>
              <Div className="col-sm-6 text-start">
                <label className="cs-primary_color">Campaign Objectives*</label>
                <Div className='flex row text-start'>
                  <label>
                    <input
                      type="checkbox"
                      checked={campaignObjective.productLaunch}
                      onChange={(e) =>
                        setCampaignObjective({
                          ...campaignObjective,
                          productLaunch: e.target.checked,
                        })
                      }
                    />
                    Product Launch
                  </label>
                  <label>
                    <input
                      type="checkbox"
                      checked={campaignObjective.brandAwareness}
                      onChange={(e) =>
                        setCampaignObjective({
                          ...campaignObjective,
                          brandAwareness: e.target.checked,
                        })
                      }
                    />
                    Brand Awareness
                  </label>
                  <label>
                    <input
                      type="checkbox"
                      checked={campaignObjective.salesGrowth}
                      onChange={(e) =>
                        setCampaignObjective({
                          ...campaignObjective,
                          salesGrowth: e.target.checked,
                        })
                      }
                    />
                    Growth in Sales
                  </label>
                  <label>
                    <input
                      type="checkbox"
                      checked={campaignObjective.websiteTraffic}
                      onChange={(e) =>
                        setCampaignObjective({
                          ...campaignObjective,
                          websiteTraffic: e.target.checked,
                        })
                      }
                    />
                    Website Traffic
                  </label>
                  <label>
                    <input
                      type="checkbox"
                      checked={campaignObjective.appDownloads}
                      onChange={(e) =>
                        setCampaignObjective({
                          ...campaignObjective,
                          appDownloads: e.target.checked,
                        })
                      }
                    />
                    App Downloads
                  </label>
                  <label>
                    <input
                      type="checkbox"
                      checked={campaignObjective.followerGrowth}
                      onChange={(e) =>
                        setCampaignObjective({
                          ...campaignObjective,
                          followerGrowth: e.target.checked,
                        })
                      }
                    />
                    Follower Growth
                  </label>
                  <label>
                    <input
                      type="checkbox"
                      checked={campaignObjective.otherObjective}
                      onChange={(e) => {
                        setCampaignObjective({
                          ...campaignObjective,
                          otherObjective: e.target.checked,
                        });
                        setIsOtherSelectedObjective(e.target.checked);
                      }}
                    />
                    Other
                  </label>
                  <input type="text" value={otherObjectiveValue} name='objective' style={{display:'none'}}/>
                  {isOtherSelectedObjective && (
                    <Div>
                      <label className="cs-primary_color">Other Campaign Objective</label>
                      <input
                        type="text"
                        className="cs-form_field"
                        name='otherObjective'
                        value={otherObjectiveValue}
                        onChange={(e) => setOtherObjectiveValue(e.target.value)}
                      />
                      <Spacing lg="20" md="20" />
                    </Div>
                  )}
                </Div>
                <Spacing lg="20" md="20" />
              </Div>
              <Div className="col-sm-12">
                <label className="cs-primary_color">Estimate Budget for Campaign*</label>
                <input
                  type="number"
                  className="cs-form_field"
                  name="brand_budget"
                  value={budget}
                  onChange={(e) => setBudget(e.target.value)}
                />
                <Spacing lg="20" md="20" />
              </Div>
              <Div className="col-sm-12">
                <label className="cs-primary_color">Describe Brand*</label>
                <textarea
                  cols="30"
                  rows="7"
                  className="cs-form_field"
                  value={brandDescription}
                  onChange={(e) => setBrandDescription(e.target.value)}
                  name="brand_description"
                ></textarea>
                <Spacing lg="25" md="25" />
              </Div>
              <Div className="col-sm-12">
                <button className="cs-btn cs-style1" onClick={(e) => sendMessage(e)}>
                  <span>Submit Inquiry</span>
                  <Icon icon="bi:arrow-right" />
                </button>
              </Div>
            </form>
          </Div>
        </Div>
      </Div>
    </>
  );
}
