import React from 'react';
import { Link } from 'react-router-dom';
import Div from '../Div';

export default function PageHeading({ title, bgSrc, pageLinkText }) {
  return (
    <Div
      className="cs-page_heading cs-style1 cs-center text-center cs-bg"
      style={{ backgroundImage: `url(${bgSrc})` }}
    >
      <Div className="container">
        <Div className="cs-page_heading_in justify-start">
          <h1 className="cs-page_title lg:text-9xl heading-font text-5xl cs-white_color ">{title}</h1>
          <h1 className="cs-page_title lg:text-9xl heading-font text-5xl stroke-text cs-white_color negative_margin ">{title}</h1>
          <ol className="breadcrumb text-uppercase">
          </ol>
        </Div>
      </Div>
    </Div>
  );
}
