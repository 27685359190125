import { Route, Routes, useParams } from "react-router-dom";
import AboutPage from "./components/Pages/AboutPage";
import BlogPage from "./components/Pages/BlogPage";
import BlogDetailsPage from "./components/Pages/BlogDetailsPage";
import ContactPage from "./components/Pages/ContactPage";
import ErrorPage from "./components/Pages/ErrorPage";
import Home from "./components/Pages/Home";
import PortfolioDetailsPage from "./components/Pages/PortfolioDetailsPage";
import ServiceDetailsPage from "./components/Pages/ServiceDetailsPage/ServiceDetailsPage";
import ServicesPage from "./components/Pages/ServicesPage";
import TeamPage from "./components/Pages/TeamPage";
import PortfolioPage from "./components/Pages/PortfolioPage";
import TeamDetails from "./components/Pages/TeamDetails";
import PhotographyAgencyHome from "./components/Pages/PhotographyAgencyHome";
import CreativePortfolioHome from "./components/Pages/CreativePortfolioHome";
import DigitalAgencyHome from "./components/Pages/DigitalAgencyHome";
import MarketingAgencyHome from "./components/Pages/MarketingAgencyHome";
import ShowcasePortfolioHome from "./components/Pages/ShowcasePortfolioHome";
import CaseStudyShowcaseHome from "./components/Pages/CaseStudyShowcaseHome";
import Layout from "./components/Layout";
import CaseStudyDetailsPage from "./components/Pages/CaseStudyDetailsPage";
import FaqPage from "./components/Pages/FaqPage";
import { Analytics } from "@vercel/analytics/react";
import { useState } from "react";
import axios from "axios";
import TalentManagement from "./components/Pages/ServiceDetailsPage/TalentMagement";
import CampaignStrategy from "./components/Pages/ServiceDetailsPage/CampaignStrategy";
import DigitalMarketing from "./components/Pages/ServiceDetailsPage/DigitalMarketing";
import BrandCollaboration from "./components/Pages/ServiceDetailsPage/BrandCollaboration";
import Production from "./components/Pages/ServiceDetailsPage/Production";
import EventManagement from "./components/Pages/ServiceDetailsPage/EventManagement";
import PublicRelations from "./components/Pages/ServiceDetailsPage/PublicRelations";

function App() {
  const [ip, setIP] = useState("");
  const getData = async () => {
    const res = await axios.get("https://api.ipify.org/?format=json");
    setIP(res.data.ip);
    console.log(ip);
  };

  getData();

  if (
    ip == "110.224.118.239" ||
    ip == "103.175.32.14" ||
    ip == "182.70.120.56" ||
    true
  ) {
    return (
      <>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<MarketingAgencyHome />} />
            <Route path="team" element={<TeamPage />}></Route>
            <Route path="marketing-agency" element={<MarketingAgencyHome />} />
            <Route path="about" element={<AboutPage />} />
            <Route path="service" element={<ServicesPage />} />
            <Route
              path="service/talent-management"
              element={<TalentManagement />}
            />
            <Route
              path="service/campaign-strategy"
              element={<CampaignStrategy />}
            />
            <Route
              path="service/digital-marketing"
              element={<DigitalMarketing />}
            />
            <Route
              path="service/brand-collaboration"
              element={<BrandCollaboration />}
            />
            <Route
              path="service/event-management"
              element={<EventManagement />}
            />
            <Route path="service/production" element={<Production />} />
            <Route
              path="service/public-relations"
              element={<PublicRelations />}
            />
            <Route
              path="service/:serviceDetailsId"
              element={<ServiceDetailsPage />}
            />
            <Route path="portfolio" element={<PortfolioPage />} />
            <Route
              path="portfolio/:portfolioDetailsId"
              element={<PortfolioDetailsPage />}
            />
            <Route path="blog" element={<BlogPage />} />
            <Route path="blog/:blogDetailsId" element={<BlogDetailsPage />} />
            <Route path="contact" element={<ContactPage />} />
          </Route>
          <Route
            path="/"
            element={<Layout headerVariant="cs-site_header_full_width" />}
          ></Route>
          <Route path="*" element={<ErrorPage />} />
        </Routes>
        <Analytics />
      </>
    );
  } else {
    return <div>404 not found</div>;
  }
}

export default App;
