import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { pageTitle } from '../../../helper'
import Accordion from '../../Accordion'
import Button from '../../Button'
import Cta from '../../Cta'
import IconBox from '../../IconBox'
import PageHeading2 from '../../PageHeading/PageHeading2'
import Div from '../../Div'
import SectionHeading from '../../SectionHeading'
import Spacing from '../../Spacing'


export default function BrandCollaboration() {
  pageTitle('Brand Collaboration');
  const params = useParams();
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <PageHeading2 
        title='Brand Collaboration'
        bgSrc='/images/service_hero_bg.jpeg'
        pageLinkText={params.serviceDetailsId}
      />
      <Spacing lg='145' md='80'/>
      <Div className="container">
        <SectionHeading 
          title="From collaborating with influencers to establishing connections with industry leaders, Envify focuses on creating connections that resonate effectively with your target audience."
          subtitle='Envify Media' 
          variant='cs-style1 text-center'
        />
        <Spacing lg='90' md='45'/>
        <Div className="row">
          <Div className="col-lg-4">
            <IconBox
              icon='/images/icons/service_icon_1.svg'
              title='Diverse Collaboration Channels:'
              subtitle='Engage in partnerships with influencers,
              and like-minded brands across various platforms and industries.'
            />
            <Spacing lg='30' md='30'/>
          </Div>
          <Div className="col-lg-4">
            <IconBox
              icon='/images/icons/service_icon_2.svg'
              title='Strategic Positioning:'
              subtitle="Leverage collaborations to position your brand
              strategically in the market, enhancing credibility and trust.
              "
            />
            <Spacing lg='30' md='30'/>
          </Div>
          <Div className="col-lg-4">
            <IconBox
              icon='/images/icons/service_icon_3.svg'
              title='Measurable Impact:'
              subtitle=' Utilize metrics and analytics to measure the success
              and impact of collaborations, providing valuable insights for future
              strategies.'
            />
            <Spacing lg='30' md='30'/>
          </Div>
        </Div>
      </Div>
      <Spacing lg='120' md='50'/>
      <Div className="container">
        <Div className="row align-items-center">
          <Div className="col-xl-5 col-lg-6">
            <Div className="cs-radius_15 cs-shine_hover_1">
              <img src="https://images.unsplash.com/photo-1517602302552-471fe67acf66?q=80&w=2835&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="Service" className='cs-radius_15 w-100' />
            </Div>
            <Spacing lg='0' md='40'/>
          </Div>
          <Div className="col-lg-6 offset-xl-1">
            <h2 className="cs-font_50 cs-m0">Our Approach</h2>
            <Spacing lg='50' md='30'/>
            <Div className="row">
              <Div className="col-lg-12">
                <ul>
                  <li>
                 <b> Targeted Partner Identification: </b> Employ a systematic approach to identify strategic partners whose values and audience resonate with your brand, fostering authentic collaborations that enhance brand credibility and reach.
                  </li>
                    <Spacing lg={20}/>
                    <li>
                    <b>Meaningful Relationship Cultivation:</b> Prioritize the cultivation of meaningful, long-term relationships with collaborators, going beyond transactional engagements. Our focus is on building connections that endure, ensuring lasting impact and resonance within your target audience.
                  </li>
                    <Spacing lg={20}/>
                    <li>
                    <b>Collaborative Synergy Strategies: </b> Develop synergy strategies that align your brand seamlessly with collaborators, creating cohesive and impactful campaigns that resonate with both your audience and theirs.
                  </li>
                    <Spacing lg={20}/>
                    <li>
                   <b> Brand Consistency Integration: </b> Ensure consistent representation of your brand across collaborative efforts, reinforcing your brand identity and values in every partnership for increased brand recall.
                  </li>
                    <Spacing lg={20}/>
                    <li>
                    <b>Performance Measurement:</b>  Utilize advanced analytics to measure the performance of brand collaborations, extracting insights to refine future strategies and maximize the impact of your partnerships.
                  </li>
                    <Spacing lg={20}/>
                </ul>
                </Div>
            </Div>
          </Div>
        </Div>
      </Div>
      {/* <TestimonialSlider /> */}
      {/* <Spacing lg='145' md='80'/> */}
      {/* <Div className="container cs-shape_wrap_4">
        <Div className="cs-shape_4"></Div>
        <Div className="cs-shape_4"></Div>
        <Div className="container">
          <Div className="row">
            <Div className="col-xl-5 col-lg-6">
              <SectionHeading
                title='Some pre questions and answers' 
                subtitle='FAQ’s'
              />
              <Spacing lg='90' md='45'/>
            </Div>
            <Div className="col-lg-6 offset-xl-1">
              <Accordion/>
            </Div>
          </Div>
        </Div>
      </Div> */}
      <Spacing lg='150' md='80'/>
      <Div className="container">
        <Cta 
          title='Let’s discuss & make <br />something <i>cool</i> together' 
          btnText='Connect For Meeting' 
          btnLink='/contact' 
          bgSrc='/images/cta_bg.jpeg'
        />
      </Div>
    </>
  )
}
